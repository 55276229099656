.loadingscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d7d9d7;
  z-index: 9999;
  transition: 0.6s;
}
.loadingscreen.hide {
  transform: translateY(-100%);
}
