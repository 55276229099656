.contact {
  width: 100%;
  height: 110vh;
}
.contact__fixed {
  position: fixed;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #d7d9d7;
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 40vh;
}
.contact .container {
  bottom: 0;
  width: 100%;
  padding-top: 6vh;
}

.contact-body {
  display: flex;
  flex-direction: column;
  justify-content: top;
  height: 100vh;
  align-items: center;
}
.contact-body .col {
  height: 50px;
}
.contact-body input {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #3d403f;
  transition: ease-in-out 0.3s;
}
.contact-body input:focus {
  background-color: transparent;
  outline: none;
  border: none;
}
.contact-body input:focus,
.contact-body input:focus-visible {
  background-color: transparent;
  outline: none;
  box-shadow: none;
  border-bottom: 2px solid #9fa6a5;
}
.contact-body input::ac {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #3d403f;
}
.contact-body .button {
  box-sizing: border-box;
  padding: 0px 20px;
  margin-top: 20px;
  color: #333;
  font-size: 1.3rem;
  font-weight: 500;
  transition: ease-in-out 0.3s;
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid #3d403f;
}

.contact-body .button:hover {
  background-color: #3d403f;
  color: #d7d9d7;
}

.contact__title p {
  font-weight: 700;
  text-transform: uppercase;
  color: #3d403f;
  margin-top: 300px;
  transform: translateY(50px);
}
.contact__footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  text-align: center;
}
.footer__info p {
  margin: 0;
  padding: 0;
}
.contact__footer a {
  color: #3d403f;
  text-decoration: none;
  padding: 5px 10px;
  font-size: 0.7rem;
}
.contact__footer-links a {
  font-size: 1rem;
}
.contact__footer-links a span {
  display: inline;
}
.contact__footer p {
  font-size: 0.7rem;
}

.contact__footer-links {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: center;
  width: 60%;
}
.email__link {
  text-transform: lowercase;
}
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

.footer__p {
  display: inline-block;
}
.contact__footer-links p {
  font-size: 1.3rem;
  color: #3d403f;
}

@media (min-width: 500px) {
  .contact__footer-links {
    width: 100%;
  }
}
@media (min-width: 600px) {
}

@media (min-width: 992px) {
  .contact-body .col {
    height: 80px;
  }
  .contact {
    height: 90vh;
  }
  .contact__title p {
    margin-top: 100px;
    transform: translateY(0px);
  }
  .contact__fixed {
    padding-bottom: 0vh;
  }
  .contact__footer-links {
    display: block;
    flex-direction: row;
    justify-content: none;
    align-items: none;
  }
  .contact__footer {
    padding: 0 50px;
    text-align: center;
    margin-bottom: 10px;
  }
  .contact__footer-links a {
    font-size: 1.3rem;
  }
  .contact__footer-links a span {
    display: inline;
  }
  .contact-body .button {
    padding: 10px 20px;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
