nav {
  position: fixed;
  top: 0px;
  padding: 10px 0 10px;
  z-index: 5;
  width: 100%;
  margin-top: -30px;
  /* background-color: rgb(0, 0, 0); */
}
.nav__title {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav__title img {
  width: 50px;
  height: 50px;
}
.nav-container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  position: relative;
}
.nav-container p {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  margin-right: 15px;
  font-weight: 700;
}

.nav-menu__button {
  margin-top: 30px;
  overflow: hidden;
  border: 1px solid #d7d9d7;
  background-color: #3d403f;
  padding: 5px 20px;
  box-shadow: 2px 2px 2px #d7d9d7;
  position: relative;
  z-index: 3;
  color: #d7d9d7;
  margin-right: 20px;
}
.nav-menu__button p {
  margin: 0 auto;
}

.nav-menu__button::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d7d9d7;
  transition: all 0.3s ease;
  z-index: -1;
}
.nav-menu__button:hover {
  color: #3d403f;
}

.nav-menu__button:hover::before {
  top: 0;
}

.nav-menu__activeElement {
  z-index: 1;
  position: absolute;
  right: 0;
  top: -20px;
  width: 100%;
  height: 400%;
}
.nav-activeElement__container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 0px 0px 0px 50px;
}

.nav-menu__circleElement {
  display: block;
  position: absolute;
  right: 0;
  top: -20px;
  background-color: #f0f2f0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.nav-menu-items {
  margin-left: 50px;
  margin-top: 100px;
  color: #0b1c26;
  text-align: right;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.nav-menu-items p {
  margin: 10px 0;
  padding-right: 0px;
  font-size: 2rem;
  text-decoration: none;
  color: #0b1c26;
}

.nav-menu-items {
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .nav-menu__activeElement {
    width: 60%;
  }
}

@media (min-width: 992px) {
  nav {
    margin-top: 0px;
  }
  .nav-menu__activeElement {
    width: 40%;
  }
  .nav-container p {
    margin-right: 0px;
    font-size: 1.5rem;
  }
  .nav-container {
    margin-top: -40px;
    padding: 10px 50px;
  }
  .nav-menu-items p {
    margin: 10px 0;
    padding-right: 90px;
    font-size: 2rem;
  }
  .nav-menu-items {
    margin-top: 150px;
  }

  .nav-menu__button {
    margin-right: 0px;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
  .nav-menu__activeElement {
    width: 30%;
  }
}
