.about {
  width: 100%;
  height: 300vh;
  background-color: #3d403f;
  overflow: hidden;
}
.marque-clouds {
  position: absolute;
  top: 10%;
  left: 0;
  width: 100%;
}
.about-title {
  width: 100%;
  height: 20%;
}
.about-title__img {
  position: absolute;
  top: 8%;
  left: 50%;
  transform: translateX(-50%);
}
.about-title__img img {
  border-radius: 0px 121px 0px 121px;
  -webkit-border-radius: 0px 121px 0px 121px;
  -moz-border-radius: 0px 121px 0px 121px;
  width: 300px;
  height: 400px;
  object-fit: cover;
}
.about-title__text {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 1.5rem;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
}
.about-content__element {
  width: 100%;
  color: white;
  position: relative;
  position: absolute;
  padding: 0 30px;
}
.about-content__element p {
  font-size: 1rem;
}
.about-content__element-oneImg {
  top: 25%;
  left: 2%;
  z-index: -1;
}
.about-content__element-oneImg img {
  display: none;
  width: 600px;
  height: 600px;
  border-radius: 121px 0px 0px 0px;
}
.about-content__element-one {
  top: 40%;
  text-align: left;
}
.about-content__element-two {
  top: 50%;
  text-align: left;
}
.about-content__element-three {
  top: 65%;
  text-align: left;
}
.about-content__element-four {
  top: 75%;
  text-align: left;
}
.about-content__element-five {
  top: 85%;
  text-align: left;
}
.about-zip {
  width: 2px;
  position: absolute;
  top: 32%;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
}
.zip__element {
  width: 200px;
  height: 300px;
  border-radius: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .cloud1 {
    width: 300px;
    height: 150px;
  }
  .cloud2 {
    width: 200px;
    height: 100px;
  }
  .cloud3 {
    width: 250px;
    height: 150px;
  }
  .about-zip {
    top: 35%;
    bottom: 0%;
  }
}

@media (min-width: 1200px) {
  .about {
    overflow: visible;
  }
  .about-title__img img {
    width: 600px;
    height: 700px;
  }
  .about-title__text {
    width: 100%;
    font-size: 2rem;
  }
  .about-content__element {
    width: 40%;
  }
  .about-content__element-one {
    right: 0%;
    text-align: right;
  }

  .about-content__element-two {
    left: 0%;
  }
  .about-content__element-three {
    text-align: right;
    right: 0%;
  }
  .about-content__element-four {
    left: 0%;
  }
  .about-content__element-five {
    text-align: right;
    right: 0%;
  }
  .about-content__element hr {
    height: 2px;
    background-color: rgb(199, 188, 41);
    opacity: 1;
    margin-top: 50px;
  }
  .zip__element {
    position: sticky;
    top: 50%;
  }
  .about-zip {
    top: 50%;
    bottom: 0%;
  }
  .cloud1 {
    width: 350px;
    height: 200px;
  }
  .cloud2 {
    width: 250px;
    height: 150px;
  }
  .cloud3 {
    width: 300px;
    height: 150px;
  }
}

@media (min-width: 1600px) {
  .zip__element {
    width: 300px;
    height: 400px;
    border-radius: 10px;
    position: sticky;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .about-content__element p {
    font-size: 1.4rem;
  }
  .about-content__element {
    width: 30%;
  }
  .about-title__text {
    width: 80%;
    font-size: 3rem;
  }
  .about-title__img img {
    width: 600px;
    height: 700px;
  }
  .about-content__element-oneImg img {
    display: block;
  }
  .cloud1 {
    width: 350px;
    height: 200px;
  }
  .cloud2 {
    width: 250px;
    height: 150px;
  }
  .cloud3 {
    width: 300px;
    height: 150px;
  }
  .about-zip {
    top: 40%;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn 0.8s ease forwards;
}

.about-content__element {
  opacity: 0;
}
