.marque {
  display: flex;
  justify-content: center;
  min-height: 200vh;
  background-color: #3d403f;
  position: relative;
}
.sticky {
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.marque-img img {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* object-position: 10% 40%; */
  /* transform: scale(1); */
}
.marque-bg {
  z-index: -1;
  position: sticky;
  top: 50%;
  transform: translateY(-50%) rotate(7deg) scale(1.5);
}
.marque-bg p {
  color: white;
  text-transform: uppercase;
  font-size: 80px;
  font-weight: 700;
}
.marque-bg p.marque-bg__event {
  color: transparent;
  -webkit-text-stroke: 1px white;
}
@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .marque-img img {
    object-position: 50% 40%;
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1600px) {
}
