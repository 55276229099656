.header {
  width: 100%;
  z-index: 0;
}
.header .container {
  height: 100vh;
}
.header-content-title p {
  font-size: 42px;
  font-family: "Toyota MR2", sans-serif;
  line-height: 1;
}

#myVideo {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: 45% 50%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  background-color: #3d403f;
}

.header-content-title p span {
  display: block;
  margin-top: -110px;
}

.hero-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120vh;
  background-color: rgb(0, 0, 0);
  z-index: 2;
  opacity: 0.6;
}
.scroll-hr {
  rotate: 90deg;
  text-align: right;
  width: 50%;
  height: 2px;
  position: absolute;
  left: 0;
  animation: scrollAnimation 1.5s linear infinite;
  animation-delay: 7s;
  opacity: 0;
  background-color: white;
}
.heder-text__conetent p {
  font-size: 16px;
}
@keyframes scrollAnimation {
  0% {
    top: -300%;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 200%;
    opacity: 0;
  }
}
p {
  cursor: default;
}
.scroll p {
  height: 40px;
  position: relative;
  align-content: center;
  cursor: pointer;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .header-content-title p {
    font-size: 80px;
  }
  .header-content-title p span {
    margin-top: -40px;
  }

  @media (min-width: 992px) {
    .header-content-title p {
      font-size: 100px;
    }
    .header-content-title p span {
      margin-top: -60px;
    }
    .heder-text__conetent p {
      font-size: 20px;
    }
  }
}

@media (min-width: 1200px) {
  .header-content-title p {
    font-size: 130px;
  }
  .header-content-title p span {
    margin-top: -70px;
  }
}

@media (min-width: 1400px) {
}
